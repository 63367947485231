<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-overlay
        :show="$apollo.loading"
        rounded="sm"
      >
        <b-card>
          <template #header>
            <div class="d-flex align-items-center">
              <!-- Back Button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <!-- event Title -->
              <h3 class="mb-0 ml-1">
                {{ event.title }}
              </h3>
            </div>
          </template>
          <b-table-simple
            bordered
            responsive
          >
            <b-tr>
              <b-td class="font-weight-bold">
                Event Type
              </b-td>
              <b-td>{{ event.type }}</b-td>
            </b-tr>
            <b-tr>
              <b-td class="font-weight-bold">
                Event Capacity
              </b-td>
              <b-td>{{ event.capacity }}</b-td>
            </b-tr>
            <b-tr v-if="event.ideal_lifecycle_stage">
              <b-td class="font-weight-bold">
                Ideal Lifecycle Stage
              </b-td>
              <b-td>{{ event.ideal_lifecycle_stage }}</b-td>
            </b-tr>
            <b-tr v-if="event.industry">
              <b-td class="font-weight-bold">
                Industry
              </b-td>
              <b-td>{{ event.industry }}</b-td>
            </b-tr>
            <b-tr v-if="event.fund_raising_assistance">
              <b-td class="font-weight-bold">
                Fund Raising Assistance
              </b-td>
              <b-td>{{ fundRaisingAssistanceDisplayLabel }}</b-td>
            </b-tr>
            <b-tr v-if="event.inhouse_funds">
              <b-td class="font-weight-bold">
                Inhouse Funds
              </b-td>
              <b-td>{{ event.inhouse_funds||"N/A" }}</b-td>
            </b-tr>
          </b-table-simple>
          <b-table-simple
            bordered
            responsive
          >
            <b-tr>
              <b-td
                class="font-weight-bold"
                colspan="2"
              >
                Deliverable
              </b-td>
              <b-td
                class="font-weight-bold"
              >
                Description
              </b-td>
            </b-tr>
            <b-tr>
              <b-td
                v-if="!event.programs_deliverablelisttables || !event.programs_deliverablelisttables.length"
                colspan="3"
              >
                <em>Nothing to show.</em>
              </b-td>
            </b-tr>
            <b-tr
              v-for="(d, i) in event.programs_deliverablelisttables"
              :key="d.id"
            >
              <b-td style="width: 60px">
                {{ i + 1 }}
              </b-td>
              <b-td>{{ d.deliverable }}</b-td>
              <b-td>{{ d.description || '-' }}</b-td>
            </b-tr>
          </b-table-simple>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>

</template>

<script>
import {
  BButton,
  BCard, BCol, BRow, BTableSimple, BTd, BTr,
} from 'bootstrap-vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BTableSimple,
    BTr,
    BTd,
  },
  data() {
    return {
      event: {
        programs_shortlistingcriteriontables: [],
        programs_deliverablelisttables: [],
      },
      required,
      email,
      fundRaisingAssistanceLabel: {
        demoDay: 'Demo Day',
        personalPitching: 'Personal Pitching',
        Liasoning: 'Liasoning',
      },
    }
  },
  computed: {
    fundRaisingAssistanceDisplayLabel() {
      return this.fundRaisingAssistanceLabel[this.event.fund_raising_assistance] || 'N/A'
    },
  },
  apollo: {
    event: {
      query() {
        return gql`
        query getEventDetails($id: Int!){
          programs_basicinfo_by_pk(id:$id) {
            type
            title
            capacity
            description
            ideal_lifecycle_stage
            industry
            programs_shortlistingcriteriontables {
              criteria
              description
            }
            programs_deliverablelisttables {
              description
              deliverable
            }
            inhouse_funds
            inhouse_ticket_size
            ticket_size_of_fund_raised
            fund_raising_assistance
        }
      }`
      },
      variables() {
        return {
          id: parseInt(this.$route.params.id, 10),
        }
      },
      update: data => data.programs_basicinfo_by_pk,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard';
@import '@core/scss/vue/libs/vue-select';
</style>
